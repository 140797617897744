import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Table, Button, Container } from "react-bootstrap";

const ClientsTable = () => {
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchClients = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_API}/clients`,
    );
    setClients(response.data);
  };

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      axios
        .get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    } else {
      navigate("/");
    }

    fetchClients();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Deseas eliminar al cliente?")) {
      try {
        await axios.delete(`${process.env.REACT_APP_URL_API}/clients/${id}`);
        setClients(clients.filter((client) => client._id !== id));
      } catch (error) {
        setError("Hubo un error al eliminar al cliente");
      }
    }
  };

  return (
    <Container>
      <h1 className="my-4">Clientes</h1>
      <Button
        variant="dark"
        className="mb-4"
        onClick={() => navigate("/clients/new")}
      >
        Agregar nuevo cliente
      </Button>
      {error ? <p className="text-danger mt-3">{error}</p> : null}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Nombre del Cliente</th>
            <th>Nombre de la Empresa</th>
            <th>Email del Cliente</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client._id}>
              <td>{client.name}</td>
              <td>{client.companyName}</td>
              <td>{client.email}</td>
              <td>
                <Button
                  variant="warning"
                  className="me-2 w-100"
                  onClick={() => navigate(`/clients/edit/${client._id}`)}
                >
                  Editar
                </Button>
                <Button
                  variant="danger mt-1 w-100"
                  onClick={() => handleDelete(client._id)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ClientsTable;
