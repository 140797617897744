import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";

const AddClient = ({ isEdit }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
  });
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClient = async () => {
      if (isEdit && id) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_URL_API}/clients/${id}`,
          );
          setFormData({
            name: data.name,
            email: data.email,
            companyName: data.companyName,
          });
        } catch {
          setError("Hubo un error al buscar los datos del cliente");
        }
      }
    };

    fetchClient();
  }, [isEdit, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEdit) {
        await axios.put(
          `${process.env.REACT_APP_URL_API}/clients/${id}`,
          formData,
        );
      } else {
        await axios.post(`${process.env.REACT_APP_URL_API}/clients`, formData);
      }
      navigate("/clients");
    } catch {
      setError("Hubo un error al enviar el formulario");
    }
  };
  return (
    <Container>
      <h1 className="my-4">{isEdit ? "Editar Cliente" : "Agregar Cliente"}</h1>
      {error ? <p className="text-danger mt-3">{error}</p> : null}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mb-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter client's name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formCompanyName" className="mb-3">
          <Form.Label>Nombre de la empresa</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter company's name"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter client's email"
            value={formData.email}
            name="email"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button variant="dark" className="w-100" type="submit">
          {isEdit ? "Actualizar Cliente" : "Agregar Cliente"}
        </Button>
      </Form>
    </Container>
  );
};

AddClient.propTypes = {
  isEdit: PropTypes.bool.isRequired,
};

export default AddClient;
