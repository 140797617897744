import axios from "axios";

export const getGoogleAnalyticsData = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_URL_API}/stadistics`,
  );

  const dates = data.rows.map((row) => {
    const rawDate = row.dimensionValues[0].value;
    return `${rawDate.slice(6, 8)}/${rawDate.slice(4, 6)}/${rawDate.slice(0, 4)}`;
  });
  const sessions = data.rows?.map((row) =>
    parseInt(row.metricValues[0].value, 10),
  );

  return {
    labels: dates,
    datasets: [
      {
        label: "Visitas",
        data: sessions,
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
};

export const getMetaData = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_URL_API}/stadistics/meta`,
  );

  return data;
};
