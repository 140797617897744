import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Form, Button, Image } from "react-bootstrap";
import PropTypes from "prop-types";

const NewService = ({ isEdit = false }) => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [src, setSrc] = useState(null);
  const [previewSrc, setPreviewSrc] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      if (formatData.first_time) {
        navigate("/");
        return;
      }

      axios
        .get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    } else {
      navigate("/");
    }

    if (isEdit) {
      const fetchService = async () => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_URL_API}/service/${id}`,
          );
          setSrc(data.src);
          setTitle(data.title);
          setDescription(data.text);
        } catch (error) {
          setError("Error fetching service data");
        }
      };

      fetchService();
    }
  }, [id, isEdit]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formServices = new FormData();

    try {
      if (isEdit) {
        formServices.append("alt", title);
        formServices.append("title", title);
        formServices.append("text", description);

        if (image) {
          formServices.append("image", image);
        }

        await axios.put(
          `${process.env.REACT_APP_URL_API}/service/${id}`,
          formServices,
        );
      } else {
        formServices.append("image", image);
        formServices.append("alt", title);
        formServices.append("title", title);
        formServices.append("text", description);
        await axios.post(
          `${process.env.REACT_APP_URL_API}/service`,
          formServices,
        );
      }
      navigate("/services");
    } catch (error) {
      setError("Error saving service");
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setImage(selectedFile);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };
    fileReader.readAsDataURL(selectedFile);
  };

  return (
    <Container className="mt-5">
      <h1>{isEdit ? "Editar servicio" : "Agregar servicio"}</h1>
      <Form>
        {isEdit ? (
          <Form.Group>
            <Form.Label>Imagen anterior</Form.Label>
            <Image
              src={src}
              alt={title}
              thumbnail
              style={{ maxHeight: "300px" }}
            />
          </Form.Group>
        ) : null}
        <Form.Group controlId="formFile">
          <Form.Label>Cargar imagen</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
        </Form.Group>

        {previewSrc && (
          <div className="mt-3">
            <p>Vista previa:</p>
            <Image
              src={previewSrc}
              alt="Preview"
              thumbnail
              style={{ maxHeight: "300px" }}
            />
          </div>
        )}

        <Form.Group controlId="formTitle" className="mt-3">
          <Form.Label>Titulo</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formText" className="mt-3">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>

        {error && <p className="text-danger mt-3">{error}</p>}

        <Button
          variant="dark"
          type="submit"
          className="mt-3 w-100"
          onClick={handleSubmit}
        >
          {isEdit ? "Guardar cambios" : "Crear servicio"}
        </Button>
      </Form>
    </Container>
  );
};

NewService.propTypes = {
  isEdit: PropTypes.bool.isRequired,
};

export default NewService;
