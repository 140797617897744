import PropTypes from "prop-types";
import "../styles/tables.css";
import React from "react";
import TableRow from "./TableRow";

const Tables = ({
  titles,
  data,
  onDelete,
  onEdit,
  editingInfo,
  saveEditingInfo,
  setImage,
  showImageInput = true,
}) => {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {titles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody className="tbody-tables">
          {data.length > 0 &&
            data.map((table) => (
              <TableRow
                key={table._id}
                table={table}
                editingInfo={editingInfo}
                onEdit={onEdit}
                onDelete={onDelete}
                saveEditingInfo={saveEditingInfo}
                setImage={setImage}
                showImageInput={showImageInput}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};
Tables.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      text: PropTypes.string,
    }),
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  editingInfo: PropTypes.object,
  saveEditingInfo: PropTypes.func.isRequired,
  setImage: PropTypes.func,
  showImageInput: PropTypes.bool,
};
export default Tables;
