import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const TableRow = ({
  table,
  editingInfo,
  onEdit,
  onDelete,
  saveEditingInfo,
  setImage,
  showImageInput,
}) => {
  const [editFields, setEditFields] = useState({
    _id: "",
    src: "",
    title: "",
    text: "",
  });
  useEffect(() => {
    if (editingInfo) {
      setEditFields({ ...editingInfo });
    }
  }, [editingInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    saveEditingInfo(editFields);
  };

  const handleEditClick = () => {
    onEdit(table);
  };

  return (
    <tr>
      <td>
        {showImageInput && editingInfo && editingInfo._id === table._id ? (
          <>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </>
        ) : (
          <img src={table.src} alt={table.alt} />
        )}
      </td>
      <td>
        {editingInfo && editingInfo._id === table._id ? (
          <input
            type="text"
            name="title"
            value={editFields.title}
            onChange={handleInputChange}
          />
        ) : (
          table.title
        )}
      </td>
      {table.text !== undefined && (
        <td>
          {editingInfo && editingInfo._id === table._id ? (
            <input
              type="text"
              name="text"
              value={editFields.text}
              onChange={handleInputChange}
            />
          ) : (
            table.text
          )}
        </td>
      )}
      <td className="delete-container">
        {editingInfo && editingInfo._id === table._id ? (
          <i className="fa-solid fa-check" onClick={handleSaveClick}></i>
        ) : (
          <>
            <i className="fa-solid fa-pen" onClick={handleEditClick}></i>
            <i
              className="fa-solid fa-trash"
              onClick={() => onDelete(table._id)}
            ></i>
          </>
        )}
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  table: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    text: PropTypes.string,
  }).isRequired,
  editingInfo: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  saveEditingInfo: PropTypes.func.isRequired,
  setImage: PropTypes.func,
  showImageInput: PropTypes.bool,
};

export default TableRow;
