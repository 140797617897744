import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const BankAccountList = () => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      const formatData = JSON.parse(localStorage.getItem("user"));

      if (formatData && formatData.token) {
        try {
          await axios.get(`${process.env.REACT_APP_URL_API}/validate`, {
            headers: {
              Authorization: `Bearer ${formatData.token}`,
            },
          });
        } catch (err) {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    };

    const fetchBankAccounts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_API}/bank`,
        );
        setBankAccounts(response.data);
      } catch (error) {
        console.error("Error fetching bank accounts:", error);
      }
    };

    fetchBankAccounts();
    verifyUser();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_URL_API}/bank/${id}`);
      setBankAccounts(bankAccounts.filter((account) => account._id !== id));
    } catch (error) {
      console.error("Error deleting bank account:", error);
    }
  };

  return (
    <Container>
      <h1 className="my-4">Cuentas Bancarias</h1>
      <Button
        variant="dark"
        className="mb-4"
        onClick={() => navigate("/bank/new")}
      >
        Crear Nueva Cuenta
      </Button>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Nombre del Banco</th>
            <th>Número de Cuenta</th>
            <th>Titular de la Cuenta</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {bankAccounts.map((account) => (
            <tr key={account._id}>
              <td>{account.bankName}</td>
              <td>{account.accountNumber}</td>
              <td>{account.accountHolderName}</td>
              <td>
                <Button
                  variant="warning"
                  className="me-2"
                  onClick={() => navigate(`/bank/edit/${account._id}`)}
                >
                  Editar
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(account._id)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default BankAccountList;
