import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Card } from "react-bootstrap";

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchServices = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_API}/service`,
      );
      setServices(data.services);
    } catch (error) {
      setError("Error fetching services");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      if (formatData.first_time) {
        navigate("/");
        return;
      }

      axios
        .get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        })
        .then(() => {})
        .catch(() => {
          navigate("/");
        });
    } else {
      navigate("/");
    }

    fetchServices();
  }, []);

  const deleteService = (id) => {
    if (window.confirm("Eliminar servicio?")) {
      axios
        .delete(`${process.env.REACT_APP_URL_API}/service/${id}`)
        .then((response) => {
          if (response) {
            setServices((prevData) =>
              prevData.filter((service) => service._id !== id),
            );
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            setError("ID incorrecto");
          } else if (err.response && err.response.status === 500) {
            setError("Se produjo un error en el servidor. Intente más tarde.");
          } else {
            setError(
              "Hubo un error al eliminar el servicio. Intente más tarde.",
            );
          }
        });
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h1>Servicios</h1>
          <Link to="/services/new">
            <Button variant="dark">Añadir nuevo servicio</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        {services.map((service) => (
          <Col key={service.id} sm={12} md={6} lg={4} className="mb-4">
            <Card>
              <Card.Img variant="top" src={service.src} alt={service.alt} />
              <Card.Body>
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>{service.text}</Card.Text>
                <Link to={`/services/edit/${service._id}`}>
                  <Button variant="warning" className="me-2">
                    Editar
                  </Button>
                </Link>
                <Button
                  variant="danger"
                  onClick={() => deleteService(service._id)}
                >
                  Eliminar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Services;
