import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../styles/newPassword.css";
import PropTypes from "prop-types";

const NewPassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      axios
        .get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    }
  }, []);

  const handleInputChange = (event) => {
    setNewPassword(event.target.value);
    setError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!newPassword) {
      setError("Debes ingresar una nueva contraseña");
      return;
    }

    const formatData = JSON.parse(localStorage.getItem("user"));
    console.log("datos de usuario de localStorage:", formatData);
    if (!formatData || !formatData.id) {
      setError("No se encontró el ID del usuario");
      return;
    }

    setIsLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_URL_API}/admin/update-password`,
        {
          id: formatData.id,
          password: newPassword,
        },
      );

      localStorage.setItem(
        "user",
        JSON.stringify({ ...formatData, first_time: false }),
      );

      setSuccessMessage("¡Contraseña cambiada exitosamente!");
      setNewPassword("");
      setError(null);

      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      setError("Hubo un error al cambiar la contraseña. Intente nuevamente.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="password-container">
      <h2>Nueva Contraseña</h2>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="newPassword">Nueva Contraseña</label>
          <input
            type="password"
            className="form-control"
            id="newPassword"
            value={newPassword}
            onChange={handleInputChange}
            required
            disabled={isLoading}
          />
        </div>

        {error && <span className="alert alert-danger">{error}</span>}
        {successMessage && (
          <span className="alert alert-success">{successMessage}</span>
        )}

        <button type="submit" className="btn btn-primary" disabled={isLoading}>
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </form>
    </div>
  );
};

NewPassword.propTypes = {
  userId: PropTypes.number,
};

export default NewPassword;
