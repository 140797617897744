import Header from "../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const NewAboutUs = () => {
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const [data] = useState([]);

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      axios
        .get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    }

    if (data.first_time) {
      navigate("/password");
    }
  }, [navigate]);

  const buttonNewAbout = (event) => {
    event.preventDefault();

    if (!image || !title.trim() || !description.trim()) {
      setError("Debes completar todos los campos");
      return;
    }

    const formAbout = new FormData();
    formAbout.append("image", image);
    formAbout.append("alt", title);
    formAbout.append("title", title);
    formAbout.append("text", description);

    axios
      .post(`${process.env.REACT_APP_URL_API}/aboutus`, formAbout)

      .then(() => {
        navigate("/aboutus");
      })

      .catch(() => {
        setError("Hubo un error, intente más tarde");
      });
  };

  return (
    <div className="form-container">
      <Header />
      <div className="title-newabout">
        <h3>Agregar nueva información sobre nosotros</h3>
      </div>
      <form action="" className="form-newabout">
        <div className="form-img">
          <label htmlFor="image">Adjuntar imágen</label>
          <input
            type="file"
            id="image"
            accept="image/*"
            required
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>

        <div className="form-spaces">
          <label htmlFor="title">Título</label>
          <input
            type="text"
            id="title"
            required
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="form-spaces">
          <label htmlFor="description">Descripción</label>
          <input
            type="text"
            id="description"
            required
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        {error && <div className="error-message">{error}</div>}

        <div className="button-container">
          <button className="button-newabout" onClick={buttonNewAbout}>
            Agregar nueva información
          </button>
        </div>
      </form>
    </div>
  );
};
export default NewAboutUs;
