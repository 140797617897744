import { Link } from "react-router-dom";
import Header from "../components/Header";
import Tables from "../components/Tables";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const titles = ["Imágen", "Título", "Descripción", "Acciones"];
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const [editingInfo, setEditingInfo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      axios
        .get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    }

    if (data.first_time) {
      navigate("/password");
    }

    axios
      .get(`${process.env.REACT_APP_URL_API}/aboutus`)
      .then((response) => {
        const newArrayAbout = response.data.info.map((c) => ({
          _id: c._id,
          src: c.src,
          alt: c.alt,
          title: c.title,
          text: c.text,
        }));
        setData(newArrayAbout);
      })
      .catch(() => {
        setError("Hubo un error, intente más tarde");
      });
  }, []);

  const deleteAboutUs = (_id) => {
    axios
      .delete(`${process.env.REACT_APP_URL_API}/aboutus/${_id}`)
      .then((response) => {
        if (response) {
          setData((prevData) =>
            prevData.filter((aboutus) => aboutus._id !== _id),
          );
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setError("ID incorrecto");
        } else if (err.response && err.response.status === 500) {
          setError("Se produjo un error. Intente más tarde.");
        } else {
          setError("Hubo un error al eliminar el banner. Intente más tarde.");
        }
      });
  };

  const handleSaveEdit = (updatedItem) => {
    const formData = new FormData();

    for (const key in updatedItem) {
      if (key !== "image" && key !== "src") {
        formData.append(key, updatedItem[key]);
      }
    }

    if (image) {
      formData.append("image", image);
    }

    axios
      .put(
        `${process.env.REACT_APP_URL_API}/aboutus/${updatedItem._id}`,
        formData,
      )
      .then(() => {
        axios
          .get(`${process.env.REACT_APP_URL_API}/aboutus`)
          .then((response) => {
            const newArrayAbout = response.data.info.map((c) => ({
              _id: c._id,
              src: c.src,
              alt: c.alt,
              title: c.title,
              text: c.text,
            }));
            setData(newArrayAbout);
          })
          .catch(() => {
            setError("Hubo un error, intente más tarde");
          });
        setEditingInfo(null);
      })
      .catch(() => {
        setError("Hubo un error en modificar los datos. Intente más tarde.");
      });
  };

  return (
    <div className="about-container">
      <Header />
      <h2>Nosotros</h2>
      {error && <div className="error-message">{error}</div>}
      <div className="link-about">
        <Link to="/aboutus/new">Agregar nueva información</Link>
      </div>
      <Tables
        titles={titles}
        data={data}
        onDelete={deleteAboutUs}
        onEdit={setEditingInfo}
        editingInfo={editingInfo}
        saveEditingInfo={handleSaveEdit}
        setImage={setImage}
      />
    </div>
  );
};

export default AboutUs;
