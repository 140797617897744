import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";

const NewBanner = () => {
  const [error, setError] = useState(null);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      axios
        .get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    } else {
      navigate("/");
    }

    if (formatData.first_time) {
      navigate("/password");
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = new FormData();
    form.append("title", title);
    form.append("image", image);

    axios
      .post(`${process.env.REACT_APP_URL_API}/banner`, form)

      .then(() => {
        navigate("/banners");
      })

      .catch(() => {
        setError("Hubo un error, intente más tarde");
      });
  };

  return (
    <Container className="mt-5">
      <h1>Agregar nuevo Banner</h1>

      <Form action="" className="form-banners">
        <Form.Group controlId="formSrc">
          <Form.Label>Adjuntar imágen</Form.Label>
          <Form.Control
            type="file"
            placeholder="Enter image URL"
            id="image"
            accept="image/*"
            value={image}
            required
            onChange={(e) => setImage(e.target.files[0])}
          />
        </Form.Group>
        <Form.Group controlId="formAlt" className="mt-3">
          <Form.Label>Título</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            value={title}
            required
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>

        {error && <p className="text-danger mt-3">{error}</p>}

        <Button
          onClick={handleSubmit}
          variant="dark"
          type="submit"
          className="mt-3 w-100"
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};

export default NewBanner;
