import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Header from "./components/Header";
import Banners from "./pages/Banners";
import NewBanner from "./pages/NewBanner";
import Services from "./pages/Services";
import NewService from "./pages/NewService";
import Messages from "./pages/Messages";
import ReplyMessage from "./pages/ReplyMessage";
import AboutUs from "./pages/AboutUs";
import NewAboutUs from "./pages/NewAboutUs";
import NewPassword from "./pages/NewPassword";
import Portfolio from "./pages/Portfolio";
import Clients from "./pages/Clients";
import NewPortfolio from "./pages/NewPortfolio";
import AddClient from "./pages/AddClient";
import Layout from "./pages/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import BankAccountList from "./pages/BankAccountList";
import BankAccountForm from "./pages/BankAccountForm";
import InvoiceForm from "./pages/InvoiceForm";
import Board from "./pages/Board";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/header" element={<Header />} />
          <Route path="/banners" element={<Banners />} />
          <Route path="/banners/new" element={<NewBanner />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/new" element={<NewService isEdit={false} />} />
          <Route
            path="/services/edit/:id"
            element={<NewService isEdit={true} />}
          />
          <Route path="/messages" element={<Messages />} />
          <Route path="/messages/reply/:id" element={<ReplyMessage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/aboutus/new" element={<NewAboutUs />} />
          <Route path="/password" element={<NewPassword />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route
            path="/portfolio/new"
            element={<NewPortfolio isEdit={false} />}
          />
          <Route
            path="/portfolio/edit/:id"
            element={<NewPortfolio isEdit={true} />}
          />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/new" element={<AddClient isEdit={false} />} />
          <Route
            path="/clients/edit/:id"
            element={<AddClient isEdit={true} />}
          />
          <Route path="/bank" element={<BankAccountList />} />
          <Route path="/bank/new" element={<BankAccountForm />} />
          <Route path="/bank/edit/:id" element={<BankAccountForm />} />
          <Route path="/invoice" element={<InvoiceForm />} />
          <Route path="/board" element={<Board />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default App;
