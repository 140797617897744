import React from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Header = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
      <Container>
        <LinkContainer to="/dashboard">
          <Navbar.Brand>Nettar Dashboard</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <LinkContainer to="/dashboard">
              <Nav.Link>Inicio</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/board">
              <Nav.Link>Tablero</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/banners">
              <Nav.Link>Banners</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/services">
              <Nav.Link>Servicios</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/messages">
              <Nav.Link>Mensajes</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/portfolio">
              <Nav.Link>Portfolio</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/clients">
              <Nav.Link>Clientes</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/bank">
              <Nav.Link>Banco</Nav.Link>
            </LinkContainer>
            <NavDropdown title="Agregar" id="basic-nav-dropdown">
              <LinkContainer to="/banners/new">
                <NavDropdown.Item>Nuevo Banner</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/services/new">
                <NavDropdown.Item>Nuevo Servicio</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/portfolio/new">
                <NavDropdown.Item>Nuevo Portfolio</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/clients/new">
                <NavDropdown.Item>Nuevo Cliente</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/bank/new">
                <NavDropdown.Item>Nuevo Cuenta Bancaria</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/invoice">
                <NavDropdown.Item>Nueva Factura</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
