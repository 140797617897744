import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formData.email || !formData.password) {
      setError("Debes completar todos los campos");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_URL_API}/admin/login`, {
        email: formData.email,
        password: formData.password,
      })
      .then(function (response) {
        const data = JSON.stringify(response.data);

        localStorage.setItem("user", data);

        if (response.data.first_time) {
          navigate("/password");
        } else {
          navigate("/dashboard");
        }
      })
      .catch(function (err) {
        if (err.response?.status === 404) {
          setError("Credenciales inválidas");
        }

        if (err.response?.status === 500) {
          setError("Hubo un error, intente más tarde");
        }
      });
  };

  return (
    <Container className="mt-5">
      <h1 className="my-4">Iniciar sesión</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={formData.email}
            onChange={handleInputChange}
            name="email"
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword" className="mt-3">
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={formData.password}
              name="password"
              onChange={handleInputChange}
              required
            />
            <InputGroup.Text
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        {error && <p className="text-danger mt-3">{error}</p>}

        <Button variant="dark" type="submit" className="mt-3">
          Ingresar
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
