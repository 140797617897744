import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal,
} from "react-bootstrap";

const Board = () => {
  const [cards, setCards] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: null,
    publishDate: "",
    column: "Instagram español",
  });
  const [preview, setPreview] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editCardId, setEditCardId] = useState(null);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_API}/cards`,
        );
        setCards(response.data);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };

    fetchCards();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setFormData({ ...formData, image: files[0] });
      setPreview(URL.createObjectURL(files[0]));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", formData.title);
    data.append("description", formData.description);
    data.append("image", formData.image);
    data.append("publishDate", formData.publishDate);
    data.append("column", formData.column);

    try {
      if (editCardId) {
        const response = await axios.put(
          `${process.env.REACT_APP_URL_API}/cards/${editCardId}`,
          data,
        );

        setCards(
          cards.map((card) =>
            card._id === editCardId ? { ...card, ...response.data.card } : card,
          ),
        );
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_URL_API}/cards`,
          data,
        );
        setCards([
          ...cards,
          { ...response.data.card, _id: response.data.card._id },
        ]);
      }
      setShowModal(false);
      setFormData({
        title: "",
        description: "",
        image: null,
        publishDate: "",
        column: "Instagram español",
      });
      setPreview(null);
    } catch (error) {
      console.error("Error saving card:", error);
    }
  };

  const handleEdit = (card) => {
    setEditCardId(card._id);
    setFormData({
      title: card.title || "",
      description: card.description || "",
      image: null,
      publishDate: card.publishDate || "",
      column: card.column || "",
    });
    setPreview(card.image);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_URL_API}/cards/${id}`);
      setCards(cards.filter((card) => card._id !== id));
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setFormData({
      title: "",
      description: "",
      image: null,
      publishDate: "",
      column: "Instagram español",
    });
    setPreview(null);
    setEditCardId(null);
  };

  return (
    <Container>
      <Button
        variant="success"
        className="mt-5 mb-2"
        onClick={() => setShowModal(true)}
      >
        Agregar Tarjeta
      </Button>
      <Row className="my-4">
        {[
          "Instagram español",
          "Instagram inglés",
          "Facebook español",
          "Facebook inglés",
        ].map((column) => (
          <Col key={column} md={3}>
            <h3>{column}</h3>
            {cards
              .filter((card) => card.column === column)
              .map((card) => (
                <Card
                  key={card._id}
                  className="mb-3 w-100 mx-auto"
                  style={{ maxWidth: "350px" }}
                >
                  {card.image && <Card.Img variant="top" src={card.image} />}
                  <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                    <Card.Text>{card.description}</Card.Text>
                    <Card.Text>
                      {card.publishDate
                        ? `Fecha y hora de publicación: ${new Date(
                            card.publishDate,
                          ).toLocaleString("es-AR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}`
                        : ""}
                    </Card.Text>
                    <div className="d-flex align-items-center justify-content-center">
                      <Button
                        variant="primary"
                        onClick={() => handleEdit(card)}
                        className="mt-0"
                      >
                        Editar
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(card._id)}
                        className="ms-2"
                      >
                        Eliminar
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              ))}
          </Col>
        ))}
      </Row>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editCardId ? "Editar Tarjeta" : "Agregar Tarjeta"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="title">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="image">
              <Form.Label>Imagen</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleInputChange}
              />
              {preview && (
                <img
                  src={preview}
                  alt="Preview"
                  style={{ width: "100%", marginTop: "10px" }}
                />
              )}
            </Form.Group>
            <Form.Group controlId="publishDate">
              <Form.Label>Fecha y Hora de Publicación</Form.Label>
              <Form.Control
                type="datetime-local"
                name="publishDate"
                value={formData.publishDate}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="column">
              <Form.Label>Columna</Form.Label>
              <Form.Select
                name="column"
                value={formData.column}
                onChange={handleInputChange}
              >
                <option>Instagram español</option>
                <option>Instagram inglés</option>
                <option>Facebook español</option>
                <option>Facebook inglés</option>
              </Form.Select>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {editCardId ? "Guardar Cambios" : "Agregar Tarjeta"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Board;
