import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Form, Button, Card } from "react-bootstrap";

const ReplyMessage = () => {
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_API}/messages/${id}`,
        );

        setMessage(data);
      } catch (error) {
        setError("Error fetching the message");
      } finally {
        setLoading(false);
      }
    };

    fetchMessage();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_URL_API}/messages/${id}/reply`,
        { response },
      );
      navigate("/messages");
    } catch (error) {
      setError("Error sending the response");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Container>
      <h1 className="my-4">Responder mensaje</h1>

      {message && (
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>{message.name}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {message.email}
            </Card.Subtitle>
            <Card.Text>{message.message}</Card.Text>
          </Card.Body>
        </Card>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formResponse">
          <Form.Label>Tu respuesta</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Type your response here..."
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            required
          />
        </Form.Group>
        {error && <p className="text-danger mt-3">{error}</p>}
        <Button variant="primary" type="submit" className="mt-3">
          Enviar
        </Button>
      </Form>
    </Container>
  );
};

export default ReplyMessage;
