import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Badge,
  Form,
  Card,
  Pagination,
} from "react-bootstrap";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      const formatData = JSON.parse(localStorage.getItem("user"));

      if (formatData && formatData.token) {
        try {
          await axios.get(`${process.env.REACT_APP_URL_API}/validate`, {
            headers: {
              Authorization: `Bearer ${formatData.token}`,
            },
          });
        } catch (err) {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    };

    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_API}/messages`,
        );

        setMessages(data.messages);
      } catch (error) {
        setError("Hubo un error al cargar los mensajes. Intente más tarde.");
      } finally {
        setLoading(false);
      }
    };

    verifyUser();
    fetchData();
  }, []);

  const handleStatusToggle = async (id, currentStatus) => {
    try {
      const newStatus =
        currentStatus === "pendiente" ? "finalizado" : "pendiente";
      await axios.put(`${process.env.REACT_APP_URL_API}/messages/${id}`, {
        state: newStatus,
      });
      setMessages(
        messages.map((message) =>
          message._id === id ? { ...message, state: newStatus } : message,
        ),
      );
    } catch (error) {
      setError("Error updating message status");
    }
  };

  const filterMessages = () => {
    let filtered = messages;

    if (searchTerm) {
      filtered = filtered.filter(
        (message) =>
          message.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          message.name?.toLowerCase().includes(searchTerm?.toLowerCase()),
      );
    }

    if (filterStatus !== "all") {
      filtered = filtered.filter(
        (message) => message.state.toLowerCase() === filterStatus.toLowerCase(),
      );
    }

    setFilteredMessages(filtered);
    setCurrentPage(1);
  };

  useEffect(() => {
    filterMessages();
  }, [searchTerm, filterStatus, messages]);

  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = filteredMessages.slice(
    indexOfFirstMessage,
    indexOfLastMessage,
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h1>Mensajes</h1>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Search by email or name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col md={3}>
          <Form.Select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All</option>
            <option value="pendiente">Pending</option>
            <option value="finalizado">Completed</option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-none d-md-block">
            <Table striped bordered hover responsive className="ms-0 mt-0">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Nombre</th>
                  <th>Mensaje</th>
                  <th>Fecha</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {currentMessages.map((message) => (
                  <tr key={message._id}>
                    <td>{message.email}</td>
                    <td>{message.name}</td>
                    <td>{message.message}</td>
                    <td>{message.createdAt}</td>
                    <td>
                      <Badge
                        bg={
                          message.state?.toLowerCase() === "pendiente"
                            ? "warning"
                            : "success"
                        }
                      >
                        {message.state?.toLowerCase() === "pendiente"
                          ? "Pendiente"
                          : "Finalizado"}
                      </Badge>
                    </td>
                    <td className="d-flex align-items-stretch">
                      <Button
                        variant="primary"
                        className="me-2 flex-grow-1 align-items-center justify-content-center"
                        as={Link}
                        to={`/messages/reply/${message._id}`}
                      >
                        Responder
                      </Button>
                      <Button
                        className="mt-3 flex-grow-1 align-items-center justify-content-center"
                        variant={
                          message.status?.toLowerCase() === "pending"
                            ? "success"
                            : "secondary"
                        }
                        onClick={() =>
                          handleStatusToggle(message._id, message.state)
                        }
                      >
                        {message.state === "pendiente"
                          ? "Completado"
                          : "Pendiente"}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-block d-md-none">
            {currentMessages.map((message) => (
              <Card key={message._id} className="mb-3">
                <Card.Body>
                  <Card.Title>{message.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {message.email}
                  </Card.Subtitle>
                  <Card.Text>{message.message}</Card.Text>
                  <Card.Text>{message.createdAt}</Card.Text>
                  <Badge
                    bg={
                      message.state?.toLowerCase() === "pendiente"
                        ? "warning"
                        : "success"
                    }
                  >
                    {message.state === "pendiente" ? "pendiente" : "finalizado"}
                  </Badge>
                  <div className="mt-3 d-flex align-items-stretch">
                    <Button
                      variant="primary"
                      className="me-2 flex-grow-1 align-items-center justify-content-center"
                      as={Link}
                      to={`/messages/reply/${message._id}`}
                    >
                      Responder
                    </Button>
                    <Button
                      className="mt-3 flex-grow-1 align-items-center justify-content-center"
                      variant={
                        message.state?.toLowerCase() === "pendiente"
                          ? "success"
                          : "secondary"
                      }
                      onClick={() =>
                        handleStatusToggle(message._id, message.state)
                      }
                    >
                      {message.state?.toLowerCase() === "pendiente"
                        ? "Finalizado"
                        : "Pendiente"}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs="auto">
          <Pagination>
            {[
              ...Array(
                Math.ceil(filteredMessages.length / messagesPerPage),
              ).keys(),
            ].map((number) => (
              <Pagination.Item
                key={number + 1}
                active={number + 1 === currentPage}
                onClick={() => paginate(number + 1)}
              >
                {number + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default Messages;
