import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

const InvoiceForm = () => {
  const [clients, setClients] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [services, setServices] = useState([{ name: "", price: "" }]);
  const [selectedBankAccount, setSelectedBankAccount] = useState("");
  const [url, setUrl] = useState("");
  const [invoiceId, setInvoiceId] = useState(null);
  const navigate = useNavigate();

  const verifyUser = async () => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      try {
        await axios.get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        });
      } catch (err) {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientsResponse = await axios.get(
          `${process.env.REACT_APP_URL_API}/clients`,
        );
        setClients(clientsResponse.data);

        const bankAccountsResponse = await axios.get(
          `${process.env.REACT_APP_URL_API}/bank`,
        );
        setBankAccounts(bankAccountsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    verifyUser();
  }, []);

  const handleServiceChange = (index, field, value) => {
    const newServices = [...services];
    newServices[index][field] = value;
    setServices(newServices);
  };

  const addService = () => {
    setServices([...services, { name: "", price: "" }]);
  };

  const removeService = (index) => {
    if (services.length > 1) {
      const newServices = [...services];
      newServices.splice(index, 1);
      setServices(newServices);
    } else {
      alert("Debe haber al menos un servicio en la factura.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      services.length === 0 ||
      services.some((service) => !service.name || !service.price)
    ) {
      alert("Debe haber al menos un servicio con nombre y precio.");
      return;
    }

    const invoiceData = {
      clientId: selectedClient,
      services: services.filter((service) => service.name && service.price),
      bankAccountId: selectedBankAccount,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_API}/invoice`,
        invoiceData,
      );
      const { pdfUrl, invoiceId } = response.data;
      setUrl(pdfUrl);
      setInvoiceId(invoiceId);
      alert("Factura creada con éxito");
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error creating invoice:", error);
    }
  };

  const handleSendEmail = async () => {
    if (!invoiceId) {
      alert("Primero debe crear la factura.");
      return;
    }

    const confirmSend = window.confirm(
      "¿Está seguro de que desea enviar la factura por email?",
    );

    if (confirmSend) {
      try {
        await axios.post(
          `${process.env.REACT_APP_URL_API}/invoice/${invoiceId}`,
        );
        alert("Factura enviada por email con éxito.");
      } catch (error) {
        console.error("Error sending invoice by email:", error);
        alert("Error al enviar la factura por email.");
      }
    }
  };

  return (
    <Container>
      <h1 className="my-4">Crear Factura</h1>
      {url ? (
        <>
          <Link to={url} target="_blank">
            Descargar factura
          </Link>
          <Button
            variant="secondary"
            className="ms-5"
            onClick={handleSendEmail}
            disabled={!invoiceId}
          >
            Enviar Factura por Email
          </Button>
        </>
      ) : null}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="client" className="mb-3">
          <Form.Label>Cliente</Form.Label>
          <Form.Select
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
            required
          >
            <option value="">Seleccione un cliente</option>
            {clients.map((client) => (
              <option key={client._id} value={client._id}>
                {client.name} - {client.companyName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Label>Servicios</Form.Label>
        {services.map((service, index) => (
          <Row key={index} className="mb-3">
            <Col md={6}>
              <Form.Group controlId={`serviceName-${index}`}>
                <Form.Label>Nombre del Servicio</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ej. Hosting, Diseño"
                  value={service.name}
                  onChange={(e) =>
                    handleServiceChange(index, "name", e.target.value)
                  }
                  required
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId={`servicePrice-${index}`}>
                <Form.Label>Precio</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ej. 1000"
                  value={service.price}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    handleServiceChange(index, "price", e.target.value)
                  }
                  required
                />
              </Form.Group>
            </Col>
            <Col md={2} className="d-flex align-items-end">
              <Button variant="danger" onClick={() => removeService(index)}>
                -
              </Button>
            </Col>
          </Row>
        ))}
        <Button variant="secondary" onClick={addService} className="mb-4">
          Agregar Servicio
        </Button>

        <Form.Group controlId="bankAccount" className="mb-3">
          <Form.Label>Cuenta Bancaria</Form.Label>
          <Form.Select
            value={selectedBankAccount}
            onChange={(e) => setSelectedBankAccount(e.target.value)}
            required
          >
            <option value="">Seleccione una cuenta bancaria</option>
            {bankAccounts.map((account) => (
              <option key={account._id} value={account._id}>
                {account.bankName} - {account.accountHolderName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Button variant="dark" className="w-100" type="submit">
          Crear Factura
        </Button>
      </Form>
    </Container>
  );
};

export default InvoiceForm;
