import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { getGoogleAnalyticsData, getMetaData } from "../utils/dataServices";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const Dashboard = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [metaData, setMetaData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData.first_time) {
      navigate("/password");
    }

    if (formatData && formatData.token) {
      axios
        .get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    }

    const fetchData = async () => {
      const analytics = await getGoogleAnalyticsData();
      const meta = await getMetaData();

      setMetaData(meta);
      setAnalyticsData(analytics);
    };
    fetchData();
  }, []);

  const barChartData = (data, label) => ({
    labels: ["nettar.web", "nettar.global"],
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: ["rgba(54, 162, 235, 0.6)", "rgba(255, 99, 132, 0.6)"],
      },
    ],
  });

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <Container fluid>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Estadísticas de la Web</Card.Title>
              {analyticsData ? (
                <Line data={analyticsData} options={options} />
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Seguidores en Instagram</Card.Title>
              <Bar data={barChartData(metaData.instagram, "Seguidores")} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>
                &quot;Me gusta&quot; en la pagina de Facebook
              </Card.Title>
              <Bar data={barChartData(metaData.facebook, "Seguidores")} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Dashboard;
