import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";

const BankAccountForm = () => {
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountAlias, setAccountAlias] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const verifyUser = async () => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      try {
        await axios.get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        });
      } catch (err) {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (id) {
      const fetchBankAccount = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_URL_API}/bank/${id}`,
          );
          const { bankName, accountNumber, accountHolderName, accountAlias } =
            response.data;
          setBankName(bankName);
          setAccountNumber(accountNumber);
          setAccountHolderName(accountHolderName);
          setAccountAlias(accountAlias);
        } catch (error) {
          console.error("Error fetching bank account:", error);
        }
      };

      fetchBankAccount();
      verifyUser();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bankAccountData = {
      bankName,
      accountNumber,
      accountHolderName,
      accountAlias,
    };

    try {
      if (id) {
        await axios.put(
          `${process.env.REACT_APP_URL_API}/bank/${id}`,
          bankAccountData,
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_URL_API}/bank`,
          bankAccountData,
        );
      }

      navigate("/bank");
    } catch (error) {
      console.error("Error saving bank account:", error);
    }
  };

  return (
    <Container>
      <h1 className="my-4">
        {id ? "Editar Cuenta Bancaria" : "Crear Nueva Cuenta Bancaria"}
      </h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBankName" className="mb-3">
          <Form.Label>Nombre del Banco</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese el nombre del banco"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAccountNumber" className="mb-3">
          <Form.Label>CBU / IBAN</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese el número de CBU / IBAN"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAccountNumber" className="mb-3">
          <Form.Label>Alias / Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese el alias o email"
            value={accountAlias}
            onChange={(e) => setAccountAlias(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAccountHolderName" className="mb-3">
          <Form.Label>Nombre del Titular</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese el nombre del titular de la cuenta"
            value={accountHolderName}
            onChange={(e) => setAccountHolderName(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="dark" type="submit" className="w-100">
          {id ? "Actualizar Cuenta" : "Crear Cuenta"}
        </Button>
      </Form>
    </Container>
  );
};

export default BankAccountForm;
