import React, { useState, useEffect } from "react";
import { Table, Button, Container, Image, Card } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

const Portfolio = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);

  const fetchPortfolios = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_API}/portfolio`,
      );
      setProjects(response.data.portfolios);
    } catch {
      setError("Hubo un error al buscar los proyectos");
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_API}/clients`,
      );
      setClients(response.data);
    } catch {
      setError("Hubo un error buscando los clientes");
    }
  };

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      axios
        .get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    } else {
      navigate("/");
    }

    fetchClients();
    fetchPortfolios();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Deseas eliminar este proyecto?")) {
      try {
        await axios.delete(`${process.env.REACT_APP_URL_API}/portfolio/${id}`);
        setProjects(projects.filter((portfolio) => portfolio._id !== id));
      } catch {
        setError("Hubo un error al eliminar");
      }
    }
  };

  const getClientName = (clientId) => {
    const client = clients.find((client) => client._id === clientId);
    return client ? client.companyName : "N/A";
  };

  return (
    <Container>
      <h1 className="my-4">Portfolio</h1>
      <Button
        variant="dark"
        className="mb-4"
        onClick={() => navigate("/portfolio/new")}
      >
        Agregar nuevo proyecto
      </Button>
      {error ? <p className="text-danger mt-3">{error}</p> : null}
      <Table
        className="ms-0 d-none d-md-block"
        striped
        bordered
        hover
        responsive
      >
        <thead>
          <tr>
            <th>Titulo</th>
            <th>Descripción</th>
            <th>Imagén</th>
            <th>Cliente</th>
            <th>Tipo de Proyecto</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {projects.length &&
            projects.map((portfolio) => (
              <tr key={portfolio._id}>
                <td>{portfolio.title}</td>
                <td>{portfolio.description}</td>
                <td>
                  <Link
                    to={portfolio.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={portfolio.image}
                      alt="imagen"
                      thumbnail
                      style={{ maxWidth: "100px" }}
                    />
                  </Link>
                </td>
                <td>{getClientName(portfolio.clientId)}</td>
                <td>{portfolio.projectType}</td>
                <td>
                  <Button
                    variant="warning"
                    className="me-2"
                    onClick={() => navigate(`/portfolio/edit/${portfolio._id}`)}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(portfolio._id)}
                  >
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="d-block d-md-none">
        {projects.map((project) => (
          <Card key={project._id} className="mb-3">
            <Card.Body>
              <Card.Title>{project.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {getClientName(project.clientId)} - {project.projectType}
              </Card.Subtitle>
              <Card.Text>{project.description}</Card.Text>
              <Card.Img variant="top" src={project.image} alt={project.title} />
              <div className="mt-3">
                <Button
                  variant="dark"
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-2"
                >
                  Ver proyecto
                </Button>
                <Button
                  variant="warning"
                  className="me-2"
                  onClick={() => navigate(`/portfolio/edit/${project._id}`)}
                >
                  Editar
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(project._id)}
                >
                  Eliminar
                </Button>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
    </Container>
  );
};

export default Portfolio;
