import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Container, Form, Button, Image } from "react-bootstrap";
import PropTypes from "prop-types";

const NewPortfolio = ({ isEdit }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    clientId: "",
    projectType: "interno",
    url: "",
    image: "",
  });
  const [prevImage, setPrevImage] = useState("");
  const [clients, setClients] = useState([]);
  const [previewSrc, setPreviewSrc] = useState("");

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_API}/clients`,
      );
      setClients(response.data);
    } catch {
      setError("Hubo un error buscando a los clientes");
    }
  };

  const fetchProject = async () => {
    if (isEdit && id) {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_API}/portfolio/${id}`,
        );
        setFormData({
          title: data.title,
          description: data.description,
          clientId: data.clientId,
          projectType: data.projectType,
          url: data.url,
        });

        setPrevImage(data.image);
      } catch {
        setError("Hubo un error buscando el proyecto");
      }
    }
  };

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));

    if (formatData && formatData.token) {
      axios
        .get(`${process.env.REACT_APP_URL_API}/validate`, {
          headers: {
            Authorization: `Bearer ${formatData.token}`,
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    } else {
      navigate("/");
    }

    fetchClients();
    fetchProject();
  }, [isEdit, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeImage = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("title", formData.title);
    data.append("description", formData.description);
    data.append("clientId", formData.clientId);
    data.append("projectType", formData.projectType);
    data.append("url", formData.url);

    if (isEdit && formData.image) {
      data.append("image", formData.image);
    } else if (!isEdit) {
      data.append("image", formData.image);
    }

    try {
      if (isEdit) {
        await axios.put(
          `${process.env.REACT_APP_URL_API}/portfolio/${id}`,
          data,
        );
      } else {
        await axios.post(`${process.env.REACT_APP_URL_API}/portfolio`, data);
      }

      navigate("/portfolio");
    } catch {
      setError("Hubo un error al enviar el formulario");
    }
  };

  return (
    <Container>
      <h1 className="my-4">
        {isEdit ? "Editar proyecto" : "Agregar nuevo proyecto"}
      </h1>
      {error ? <p className="text-danger mt-3">{error}</p> : null}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle" className="mb-3">
          <Form.Label>Titulo</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter project title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formDescription" className="mb-3">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter project description"
            value={formData.description}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formClient" className="mb-3">
          <Form.Label>Cliente</Form.Label>
          <Form.Select
            name="clientId"
            value={formData.clientId}
            onChange={handleChange}
            required
          >
            <option value="">Seleccionar un cliente</option>
            {clients.map((client) => (
              <option key={client._id} value={client._id}>
                {client.companyName || client.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="formProjectType" className="mb-3">
          <Form.Label>Tipo de proyecto</Form.Label>
          <Form.Select
            value={formData.projectType}
            name="projectType"
            onChange={handleChange}
            required
          >
            <option value="interno">Interno</option>
            <option value="cliente">Cliente</option>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="formUrl" className="mb-3">
          <Form.Label>URL</Form.Label>
          <Form.Control
            type="url"
            name="url"
            placeholder="Enter project URL"
            value={formData.url}
            onChange={handleChange}
            required
          />
        </Form.Group>
        {previewSrc && (
          <div className="mt-3">
            <p>Vista previa:</p>
            <Image
              src={previewSrc}
              alt="Preview"
              thumbnail
              style={{ maxHeight: "300px" }}
            />
          </div>
        )}
        <Form.Group controlId="formImage" className="mb-3">
          <Form.Label>Imagen</Form.Label>
          <Form.Control
            type="file"
            name="image"
            onChange={handleChangeImage}
            required={!isEdit}
          />
        </Form.Group>
        {prevImage ? (
          <Form.Group controlId="prevImage" className="mb-3">
            <Image
              src={prevImage}
              alt="imagen anterior"
              thumbnail
              style={{ maxWidth: "100px" }}
            />
          </Form.Group>
        ) : null}
        <Button variant="dark" className="w-100" type="submit">
          {isEdit ? "Guardar cambios" : "Agregar proyecto"}
        </Button>
      </Form>
    </Container>
  );
};

NewPortfolio.propTypes = {
  isEdit: PropTypes.bool.isRequired,
};

export default NewPortfolio;
